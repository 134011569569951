export let survey = {
  options: {
    tooltip: {
      trigger: 'axis',
      type: 'line',
      formatter: function (params) {
        let str = `00:00-${params[0].axisValue}:00<br/>`;
        for (let i in params) {
          str += `${params[i].marker} ${params[i].seriesName}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${params[i].value}<br/>`;
        }
        return str;
      },
      backgroundColor: 'rgba(0,0,0,0.6)',
      textStyle: {
        color: '#fff',
      },
      axisPointer: {
        type: 'cross',
        lineStyle: {
          type: 'solid',
        },
      },
    },
    legend: {
      data: ['今日', '昨日'],
      icon: 'circle',
      right: 'top',
      itemWidth: 10, // 设置宽度
      itemHeight: 10, // 设置高度
      itemGap: 15, // 设置间距，
    },
    grid: {
      top: '15%',
      left: '0%',
      right: '2%',
      bottom: '0%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: [],
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: true,
        lineStyle: {
          type: 'dashed',
        },
      },
    },
    series: [],
  },
  options1: {
    tooltip: {
      trigger: 'axis',
    },
    grid: {
      top: '8%',
      left: '0%',
      right: '2%',
      bottom: '0%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
    },
    yAxis: {
      min: 0,
      max: 100,
      axisLabel: {
        formatter(val) {
          return val + '%';
        },
      },
    },
    series: {
      name: '联盟广告',
      type: 'line',
      stack: '总量',
      data: [10, 80, 19],
      smooth: true,
    },
  },

  options3: {
    tooltip: {
      trigger: 'axis',
      formatter: function (params) {
        let str = new Date().getFullYear() + '-' + params[0].axisValue + '<br/>';
        for (let i in params) {
          str += `${params[i].marker} ${params[i].seriesName}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${params[i].value}<br/>`;
        }
        return str;
      },
      axisPointer: {
        type: 'cross',
        lineStyle: {
          type: 'solid',
        },
      },
    },
    legend: {
      data: ['支付金额', '支付笔数'],
      icon: 'circle',
      right: 'center',
      itemWidth: 10, // 设置宽度
      itemHeight: 10, // 设置高度
      itemGap: 15, // 设置间距，
    },
    grid: {
      // top: '%',
      left: '0%',
      right: '1%',
      bottom: '0%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: [],
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: true,
        lineStyle: {
          type: 'dashed',
        },
      },
    },
    series: [],
  },
  options4: {
    tooltip: {
      trigger: 'axis',
      formatter: function (params) {
        let year = new Date().getFullYear();
        let str = `${year}-${params[0].name}<br/>`;
        for (let i in params) {
          str += `${params[i].marker}${params[i].seriesName}：${params[i].value}<br/>`;
        }
        return str;
      },
      axisPointer: {
        type: 'cross',
        lineStyle: {
          type: 'solid',
        },
      },
    },

    legend: {
      data: [],
      icon: 'circle',
      right: 'center',
      itemWidth: 10, // 设置宽度
      itemHeight: 10, // 设置高度
      itemGap: 15, // 设置间距，
    },
    grid: {
      top: '15%',
      left: '0%',
      right: '2%',
      bottom: '0%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: [],
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: true,
        lineStyle: {
          type: 'dashed',
        },
      },
    },
    series: [],
  },
  options5: {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        lineStyle: {
          type: 'solid',
        },
      },
    },
    grid: {
      top: '15%',
      left: '2%',
      right: '5%',
      bottom: '19%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: [],
    },
    yAxis: {},
    series: {
      name: '成功退款金额',
      type: 'line',
      data: [],
      smooth: true,
    },
  },
  options6: {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        lineStyle: {
          type: 'solid',
        },
      },
    },
    grid: {
      top: '15%',
      left: '0%',
      right: '5%',
      bottom: '0%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: [],
    },
    yAxis: {
      min: 0,
      max: 100,
      axisLabel: {
        formatter(val) {
          return val + '%';
        },
      },
    },
    series: {
      name: '退款率',
      type: 'line',
      data: [],
      smooth: true,
    },
  },
  options7: {
    graphic: {
      //图形中间文字
      type: 'text',
      left: 'center',
      top: 'center',
      style: {
        width:10,
        height:4,
        backgroundColor:'#605EF3'
      },
    },
    series: [
      {
        type: 'pie',
        radius: ['55%', '70%'],
        avoidLabelOverlap: false,
        hoverAnimation: false,
        label: {
          show: false,
          position: 'center',
        },
        labelLine: {
          show: false,
        },
        data: [{ value: 0 ,itemStyle:{color:'#605EF3'}}, { value: 0, itemStyle: { color: '#F7F8FA', emphasis: { color: '#F7F8FA' } } }],
      },
    ],
  },
  options8: {
    graphic: {
      //图形中间文字
      type: 'text',
      left: 'center',
      top: 'center',
      style: {
        width:10,
        height:4,
        backgroundColor:'#0BCED9'
      },
    },
    series: [
      {
        type: 'pie',
        radius: ['55%', '70%'],
        avoidLabelOverlap: false,
        hoverAnimation: false,
        label: {
          show: false,
          position: 'center',
        },
        labelLine: {
          show: false,
        },
        data: [{ value: 0 ,itemStyle:{color:'#0BCED9'}}, { value: 0, itemStyle: { color: '#F7F8FA', emphasis: { color: '#F7F8FA' } } }],
      },
    ],
  },
  options9: {
    graphic: {
      //图形中间文字
      type: 'text',
      left: 'center',
      top: 'center',
      style: {
        width:10,
        height:4,
        backgroundColor:'#FD5E94'
      },
    },
    series: [
      {
        type: 'pie',
        radius: ['55%', '70%'],
        avoidLabelOverlap: false,
        hoverAnimation: false, //禁止饼图高亮
        label: {
          show: false,
          position: 'center',
        },
        labelLine: {
          show: false,
        },
        data: [{ value: 0 ,itemStyle:{color:'#FD5E94'}}, { value: 0, itemStyle: { color: '#F7F8FA', emphasis: { color: '#F7F8FA' } } }],
      },
    ],
  },
};
export let analysis = {
  options: {
    tooltip: {
      trigger: 'item',
      formatter: function (params) {
        if (params.percent) {
          return `${params.name} : ${params.percent}`;
        } else {
          return '';
        }
      },
    },
    legend: {
      bottom: 10,
      left: 'center',
      data: [],
      show: false,
    },
    graphic: {
      //图形中间文字
      type: 'text',
      left: 'center',
      top: 'center',
      style: {
        text: '',
        textAlign: 'center',
        fill: '#333',
        fontSize: 14,
      },
    },
    series: [
      {
        type: 'pie',
        center: ['50%', '50%'],
        selectedMode: 'single',
        radius: ['50%', '70%'],
        data: [],
        label: {
          normal: {
            formatter(params) {
              return params.name + '：' + params.value + '%';
            },
          },
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
      },
    ],
  },
  options1: {
    tooltip: {
      trigger: 'axis',
      formatter: function (params) {
        let year = new Date().getFullYear();
        let str = `${year}-${params[0].name}<br/>`;
        for (let i in params) {
          str += `${params[i].marker}${params[i].seriesName}：${params[i].value}<br/>`;
        }
        return str;
      },
      axisPointer: {
        type: 'cross',
        lineStyle: {
          type: 'solid',
        },
      },
    },
    legend: {
      data: [],
      icon: 'circle',
      right: 'center',
      itemWidth: 10, // 设置宽度
      itemHeight: 10, // 设置高度
      itemGap: 15, // 设置间距，
    },
    grid: {
      top: '15%',
      left: '0%',
      right: '2%',
      bottom: '0%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: [],
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: true,
        lineStyle: {
          type: 'dashed',
        },
      },
    },
    series: [],
  },
};

export let goodsAnalysis = {
  options: {
    tooltip: {
      trigger: 'axis',
      formatter: function (params) {
        let year = new Date().getFullYear();
        let str = `${year}-${params[0].name}<br/>`;
        for (let i in params) {
          str += `${params[i].marker}${params[i].seriesName}：${params[i].value}<br/>`;
        }
        return str;
      },
      axisPointer: {
        type: 'cross',
        lineStyle: {
          type: 'solid',
        },
      },
    },
    legend: {
      data: [],
      icon: 'circle',
      right: 'center',
      itemWidth: 10, // 设置宽度
      itemHeight: 10, // 设置高度
      itemGap: 15, // 设置间距，
    },
    grid: {
      top: '15%',
      left: '0%',
      right: '2%',
      bottom: '0%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: [],
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: true,
        lineStyle: {
          type: 'dashed',
        },
      },
    },
    series: [],
  },
  options1: {
    title: {
      text: '类目支付金额占比',
      left: 20,
      textStyle: {
        fontSize: 14,
        color: '#333',
      },
    },
    tooltip: {
      trigger: 'item',
      formatter: function (params) {
        if (params.percent) {
          return `${params.name} : ${params.percent}`;
        } else {
          return '';
        }
      },
    },
    legend: {
      bottom: 10,
      left: 'center',
      data: [],
      show: false,
    },
    graphic: {
      //图形中间文字
      type: 'text',
      left: 'center',
      top: 'center',
      style: {
        text: '',
        textAlign: 'center',
        fill: '#333',
        fontSize: 14,
      },
    },
    series: [
      {
        type: 'pie',
        // roseType: 'area',
        center: ['50%', '50%'],
        selectedMode: 'single',
        radius: ['50%', '70%'],
        data: [],
        label: {
          normal: {
            formatter(params) {
              return params.name + '：' + params.value + '%';
            },
          },
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
      },
    ],
  },
};

export let realAnalysis = {
  options: {
    tooltip: {
      trigger: 'axis',
      formatter: function (params) {
        let year = new Date().getFullYear();
        let str = `${year}-${params[0].name}<br/>`;
        for (let i in params) {
          str += `${params[i].marker}${params[i].seriesName}：${params[i].value}<br/>`;
        }
        return str;
      },
      axisPointer: {
        type: 'cross',
        lineStyle: {
          type: 'solid',
        },
      },
    },
    grid: {
      top: '15%',
      left: '0%',
      right: '1%',
      bottom: '0%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: [],
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: true,
        lineStyle: {
          type: 'dashed',
        },
      },
    },
    series: {},
  },
};
