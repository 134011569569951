<template>
  <el-main>
    <el-form class="select_store" label-width="85px">
      <el-form-item label="选择店铺：">
        <el-select v-model="store_id" filterable placeholder="请选择" @change="selectStore">
          <el-option v-for="item in storeList" :key="item.id" :label="item.store_name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div class="day-data">
      <div class="header">
        <span></span>
        <span>实时概况</span>
        <span>更新时间：{{ order_total_price.update_time }}</span>
      </div>
      <div class="day-content">
        <div class="day-table">
          <div class="day-price">
            <p>支付金额(元)</p>
            <p>{{ order_total_price.today_before }}</p>
            <p>昨天全天：{{ order_total_price.yesterday_before }}</p>
          </div>
          <div id="day-table"></div>
        </div>
        <div class="day-num">
          <div class="item" v-for="(item, index) in day_num_list" :key="index">
            <el-image :src="item.icon"></el-image>
            <div class="price">
              <p>{{ item.name }}</p>
              <p>{{ item.today_before }}</p>
              <p>昨天全天：{{ item.yesterday_before }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="data">
      <div class="tab">
        <el-tabs v-model="tab" @tab-click="handleClick(tab)">
          <el-tab-pane label="运营视窗" name="0">
            <div class="header">
              <span></span>
              <span>整体看板</span>
            </div>
            <overallKanban v-if="thirtyDataKanban.is_show" @getThirtyDataKanban="getThirtyDataKanban" ref="whole_kanban" :type="1" domId="whole-kanban" :content="thirtyDataKanban"></overallKanban>
            <div class="refund_kanban">
              <div class="header">
                <span></span>
                <span>网店流量看板</span>
              </div>
              <!-- <div class="line-table">
                <div class="line-table-item">
                  <div class="line_num">
                    <div>跳失率</div>
                    <div>0.00%</div>
                    <div>较前一日</div>
                    <div>较上周同期</div>
                  </div>
                  <div id="jump-table"></div>
                </div>
                <div class="line-table-item"></div>
                <div class="line-table-item"></div>
              </div> -->
              <div class="circle-talbe">
                <div class="circle-talbe-item">
                  <div id="circle"></div>
                  <div class="circle-talbe-num">
                    <div>商品详情访问转化</div>
                    <div>
                      较前一日
                      <span>{{ Math.floor(conversion.goods_visit.yestoday_goods_visit_redio * 100) / 100 }}%</span>
                    </div>
                    <div>
                      较上周同期
                      <span>{{ Math.floor(conversion.goods_visit.last_week_goods_visit_redio * 100) / 100 }}%</span>
                    </div>
                  </div>
                </div>
                <div class="circle-talbe-item">
                  <div id="circle2"></div>
                  <div class="circle-talbe-num">
                    <div>访问-加购转化率</div>
                    <div>
                      较前一日
                      <span>{{ Math.floor(conversion.add_carts.yestoday_add_carts_redio * 100) / 100 }}%</span>
                    </div>
                    <div>
                      较上周同期
                      <span>{{ Math.floor(conversion.add_carts.last_week_add_carts_redio * 100) / 100 }}%</span>
                    </div>
                  </div>
                </div>
                <div class="circle-talbe-item">
                  <div id="circle3"></div>
                  <div class="circle-talbe-num">
                    <div>访问-付款转化率</div>
                    <div>
                      较前一日
                      <span>{{ Math.floor(conversion.payment_people.payment_people_num_yesterday_redio * 100) / 100 }}%</span>
                    </div>
                    <div>
                      较上周同期
                      <span>{{ Math.floor(conversion.payment_people.payment_people_num_last_week_redio * 100) / 100 }}%</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="goods_kanban">
              <div class="header">
                <span></span>
                <span>商品看板</span>
              </div>
              <goodsRank :rankList="rankList"></goodsRank>
            </div>
          </el-tab-pane>
          <el-tab-pane label="管理视窗" name="1">
            <div class="header" ref="header">
              <span></span>
              <span>整体看板</span>
            </div>
            <div class="target-pay">
              <div class="target-pay-num">
                <div class="item">
                  <p>当天支付金额(元)</p>
                  <p>{{ thirtyData.today_pay_success_num }}</p>
                </div>
                <div class="item">
                  <p>本月已完成(元)</p>
                  <p>{{ thirtyData.month_pay_success_num }}</p>
                </div>
                <div class="item">
                  <p>本月目标(元)</p>
                  <p v-if="thirtyData.month_pay_success_num_target > 0">{{ thirtyData.month_pay_success_num_target }}</p>
                  <router-link v-else to="/set/businessTarget">
                    <el-link type="primary">去设置</el-link>
                  </router-link>
                </div>
                <div class="item">
                  <p>本月进度</p>
                  <p>{{ thirtyData.month_ratio }}%</p>
                </div>
              </div>
              <div id="target-pay-table"></div>
            </div>
            <div class="header">
              <span></span>
              <span>退款看板</span>
            </div>
            <div class="refund-success">
              <div class="refund-success-item">
                <div class="refund-num">
                  <div>成功退款金额（元）</div>
                  <div>{{ successRefund.refund_success_num.today_before }}</div>
                  <div>
                    <span>较前一日</span>
                    <span>{{ successRefund.refund_success_num.yestoday_conversion }}%</span>
                  </div>
                  <div>
                    <span>较上周同期</span>
                    <span>{{ successRefund.refund_success_num.last_week_conversion }}%</span>
                  </div>
                </div>
                <div id="refund_success"></div>
              </div>
              <div class="refund-success-item">
                <div class="refund-num">
                  <div>退款率</div>
                  <div>{{ successRefund.refund_success_num_redio.today_before }}%</div>
                  <div>
                    <span>较前一日</span>
                    <span>{{ successRefund.refund_success_num_redio.yestoday_conversion }}%</span>
                  </div>
                  <div>
                    <span>较上周同期</span>
                    <span>{{ successRefund.refund_success_num_redio.last_week_conversion }}%</span>
                  </div>
                </div>
                <div id="refund_radio"></div>
              </div>
            </div>
            <div class="ranks">
              <div class="goods-rank">
                <div class="title">
                  <div>排名</div>
                  <div>商品</div>
                  <div>成功退款订单数</div>
                  <div>成功退款金额（元）</div>
                </div>
                <div class="item" v-for="(item, indexs) in successRefund.refund_goods_rank" :key="indexs">
                  <div class="rank">{{ indexs + 1 }}</div>
                  <div class="goods-info">
                    {{ item.title }}
                  </div>
                  <div class="num">{{ item.count }}</div>
                  <div class="num">{{ item.refund_amount }}</div>
                </div>
                <div class="no" v-if="!successRefund.refund_goods_rank.length">暂无数据</div>
              </div>
              <div class="refund-rank">
                <div class="title">
                  <div>排名</div>
                  <div>商品</div>
                  <div>成功退款订单数</div>
                  <div>占比</div>
                </div>
                <div class="item" v-for="(item, indexs) in successRefund.refund_reason_redio" :key="indexs">
                  <div class="rank">{{ indexs + 1 }}</div>
                  <div class="goods-info">
                    {{ item.refund_reason_name }}
                  </div>
                  <div class="num">{{ item.count }}</div>
                  <div class="num">{{ item.refund_redio }}%</div>
                </div>
                <div class="no" v-if="!successRefund.refund_reason_redio.length">暂无数据</div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
        <el-form class="date" label-width="85px">
          <el-form-item label="统计时间：" size="small">
            <el-date-picker v-model="date" value-format="yyyy-MM" default-value type="month" placeholder="选择日期" @change="getTime"></el-date-picker>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </el-main>
</template>

<script>
import * as echarts from 'echarts';
import { getDateformat } from '@/util/getDate';
import { survey } from './data.js';
import goodsRank from './components/goodsRank';
import overallKanban from './components/overallKanban';
export default {
  components: {
    goodsRank,
    overallKanban,
  },
  data() {
    return {
      store_id: -1,
      date: new Date(),
      storeList: [],
      tab: '0',
      order_total_price: {
        data: survey.options,
        today_before: 0,
        yesterday_before: 0,
        update_time: getDateformat(Date.parse(new Date()) / 1000),
      },
      day_num_list: [
        {
          icon: require('@/assets/image/price-icon0.png'),
          name: '支付人数',
          today_before: 0,
          yesterday_before: 0.0,
          key: 'payment_people_num',
        },
        {
          icon: require('@/assets/image/price-icon1.png'),
          name: '支付订单数',
          today_before: 0,
          yesterday_before: 0.0,
          key: 'payment_order_num',
        },
        {
          icon: require('@/assets/image/price-icon2.png'),
          name: '成功退款金额(元)',
          today_before: 0,
          yesterday_before: 0.0,
          key: 'refund_success_num',
        },
        {
          icon: require('@/assets/image/price-icon3.png'),
          name: '客单价(元)',
          price: 0,
          yesterday_before: 0.0,
          key: 'unit_price',
        },
        {
          icon: require('@/assets/image/price-icon4.png'),
          name: '访问量',
          today_before: 0,
          yesterday_before: 0.0,
          key: 'visitor_num',
        },
        {
          icon: require('@/assets/image/price-icon5.png'),
          name: '浏览数',
          today_before: 0,
          yesterday_before: 0.0,
          key: 'visitor_goods_num',
        },
        {
          icon: require('@/assets/image/price-icon6.png'),
          name: '储值金额(本金)(元)',
          today_before: 0,
          yesterday_before: 0.0,
          key: 'payment_num',
        },
        {
          icon: require('@/assets/image/price-icon7.png'),
          name: '储值卡销售金额(元)',
          today_before: 0,
          yesterday_before: 0.0,
          key: 'payment_total_price',
        },
      ],
      thirtyData: {},
      thirtyDataKanban: {
        is_show: !1,
        data: survey.options4,
        date: [],
        num_list: [
          {
            name: '支付金额',
            plate_type: 'payment_amount',
            key: 'today_pay_success_num_price', //匹配整体看板界面下面echarts表格，接口返回的数据key的赋值
            key_val: 'order_total_price', //匹配整体看板界面上面选项，接口返回的数据key的赋值
            checked: !0,
          },
          {
            name: '支付订单数',
            plate_type: 'payment_nums',
            key: 'today_pay_success_num',
            key_val: 'payment_order_num',
            checked: !1,
          },
          {
            name: '访问量',
            plate_type: 'visitor_num',
            key: 'visit_num',
            key_val: 'visitor_num',
            checked: !1,
          },
          {
            name: '商品浏览数',
            plate_type: 'visitor_goods_num',
            key: 'goods_visit_num',
            key_val: 'visitor_goods_num',
            checked: !1,
          },
          {
            name: '支付人数',
            plate_type: 'number_payers',
            key: 'payment_people_num',
            key_val: 'payment_people_num',
            checked: !1,
          },
          {
            name: '客单价',
            plate_type: 'unit_price',
            key: 'unit_price_pre',
            key_val: 'unit_price',
            checked: !1,
          },
          {
            name: '成功退款金额',
            plate_type: 'refund_success',
            key: 'refund_goods_list_success',
            key_val: 'refund_success_num',
            checked: !1,
          },
          {
            name: '新增会员数',
            plate_type: 'new_open',
            key: 'new_open_num',
            key_val: 'new_user_open',
            checked: !1,
          },
          {
            name: '储值金额',
            plate_type: 'store_amount',
            key: 'card_recharge_success',
            key_val: 'payment_num',
            checked: !1,
          },
          {
            name: '储值卡销售金额',
            plate_type: 'stored_value_cards',
            key: 'card_user_open',
            key_val: 'payment_total_price',
            checked: !1,
          },
        ],
      },
      //运营窗口整体看板请求参数
      thirtyDataKanbanForm: {
        store_id: -1,
        tab_type: 'operation_windows',
        date: getDateformat(Date.parse(new Date()) / 1000).slice(0, 7),
        plate_type: '', //payment_amount—支付金额 payment_nums 支付订单数 visitor_num 访客数 visitor_goods_num 商品浏览数 number_payers 支付人数 unit_price 客单价 refund_success 成功退款金额 new_open 新增会员数 store_amount 储值金额 stored_value_cards 储值卡销售金额
      },
      //管理窗口请求参数
      thirtyDataForm: {
        tab_type: 'management_windows',
        date: getDateformat(Date.parse(new Date()) / 1000).slice(0, 7),
        store_id: -1,
      },
      successRefund: {
        refund_goods_rank: [],
        refund_reason_redio: [],
        refund_success_num: {},
        refund_success_num_redio: {},
      },
      conversion: {
        add_carts: {},
        goods_visit: {},
        payment_people: {},
      },
      jump_optons: survey.options1,
    };
  },
  computed: {
    rankList() {
      return this.$store.state.utils.goodsRankList;
    },
  },
  async created() {
    this.getStore();
    this.$store.dispatch('utils/getAnkingList', { store_id: this.store_id, is_today: 0 });
    await this.getDataOverView();
    this.getThirtyDataKanban(0);
    this.getConversion();
  },
  methods: {
    handleClick(index) {
      if (index == 1) {
        if (!this.thirtyData.date) {
          this.getThirtyData();
          this.getSuccessRefund();
        }
      }
    },
    //选择门店
    selectStore() {
      let thirtyDataKanban = this.thirtyDataKanban;
      this.thirtyDataKanbanForm.store_id = this.store_id;
      this.thirtyDataForm.store_id = this.store_id;
      if (this.tab == 1) this.getThirtyData();
      thirtyDataKanban.num_list.map(item => {
        item.num_arr = '';
        item.checked = !1;
      });
      this.getThirtyDataKanban(0);
      this.getDataOverView();
    },
    getTime(val) {
      let thirtyDataKanban = this.thirtyDataKanban;
      this.thirtyDataKanbanForm.date = val;
      this.thirtyDataForm.date = val;
      if (this.tab == 1) this.getThirtyData();
      thirtyDataKanban.num_list.map(item => {
        item.num_arr = '';
        item.checked = !1;
      });
      this.getThirtyDataKanban(0);
    },
    //获取实时概况数据
    getDataOverView() {
      return new Promise(resolve => {
        this.$axios.post(this.$api.data.dataOverView, { store_id: this.store_id }).then(res => {
          if (res.code == 0) {
            let day_num_list = this.day_num_list;
            let info = res.result;
            let dateday = info.order_total_price.dateday;
            let data = this.order_total_price.data;
            let num_list = this.thirtyDataKanban.num_list;
            //赋值实时概况右侧的数据
            for (let i in day_num_list) {
              for (let y in info) {
                if (day_num_list[i].key == y) {
                  day_num_list[i].today_before = info[y].today_before || 0;
                  day_num_list[i].yesterday_before = info[y].yesterday_before || 0;
                }
              }
            }
            //赋值整体看板上面的数据
            for (let i in num_list) {
              for (let y in info) {
                if (num_list[i].key_val == y) {
                  num_list[i].num_today = info[y].today_before;
                  num_list[i].num_yesterday = info[y].yestoday_conversion;
                  num_list[i].num_last_week = info[y].last_week_conversion;
                }
              }
            }
            this.thirtyDataKanban.is_show = !0; //从这里开始加载组件显示
            this.thirtyDataKanban.num_list = num_list;
            let date = [...dateday.date];
            data.xAxis.data = date;
            data.series = [
              {
                name: '今日',
                type: 'line',
                data: dateday.today_pay_success_num,
              },
              {
                name: '昨日',
                type: 'line',
                data: dateday.yesterday_pay_success_num,
              },
            ];
            this.order_total_price.today_before = info.order_total_price.today_before || '0.00';
            this.order_total_price.yesterday_before = info.order_total_price.yesterday_before || '0.00';
            let chartDom = document.getElementById('day-table');
            let myChart = echarts.init(chartDom);
            myChart.setOption(data);
            resolve();
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    //获取运营视窗-整体看板
    getThirtyDataKanban(index) {
      let thirtyDataKanbanForm = this.thirtyDataKanbanForm;
      let currentInfo = this.thirtyDataKanban.num_list[index];
      thirtyDataKanbanForm.plate_type = currentInfo.plate_type;
      this.$axios.post(this.$api.data.getThirtyData, thirtyDataKanbanForm).then(res => {
        if (res.code == 0) {
          let info = res.result.date_month;
          currentInfo.num_arr = info[currentInfo.key];
          currentInfo.checked = !0;
          this.thirtyDataKanban.date = info.date;
          this.$refs.whole_kanban.initData();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //获取管理视窗-整体看板数据
    getThirtyData() {
      this.$axios.post(this.$api.data.getThirtyData, this.thirtyDataForm).then(res => {
        if (res.code == 0) {
          console.log(res);
          let info = res.result;
          let data = survey.options3;
          data.xAxis.data = info.date;
          data.series = [
            {
              name: '支付金额',
              type: 'line',
              data: info.today_pay_success_num_price,
            },
            {
              name: '支付笔数',
              type: 'line',
              data: info.today_pay_success_nums,
            },
          ];
          document.getElementById('target-pay-table').style.width = document.querySelector('.header').offsetWidth + 'px';
          let chartDom = document.getElementById('target-pay-table');
          let myChart = echarts.init(chartDom);
          this.thirtyData = info;
          myChart.setOption(data);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //获取管理窗口-退款看板数据
    getSuccessRefund() {
      this.$axios.post(this.$api.data.successRefund, { tab_type: 'management_windows' }).then(res => {
        if (res.code == 0) {
          let info = res.result.date_month;
          let data1 = survey.options5;
          let data2 = survey.options6;
          data1.xAxis.data = info.date;
          data2.xAxis.data = info.date;
          data1.series.data = info.refund_goods_list_success;
          data2.series.data = info.refund_radio;
          document.getElementById('refund_success').style.width = document.querySelector('.refund-num').offsetWidth + 'px';
          document.getElementById('refund_radio').style.width = document.querySelector('.refund-num').offsetWidth + 'px';
          let myChart1 = echarts.init(document.getElementById('refund_success'));
          let myChart2 = echarts.init(document.getElementById('refund_radio'));
          this.successRefund = info;
          myChart1.setOption(data1);
          myChart2.setOption(data2);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //获取转化率数据
    getConversion() {
      this.$axios.post(this.$api.data.conversion).then(res => {
        if (res.code == 0) {
          let { add_carts, goods_visit, payment_people } = res.result;
          let { options7, options8, options9 } = survey;
          options7.series[0].data[0].value = goods_visit.today_goods_visit_redio;
          options7.series[0].data[1].value = 100 - goods_visit.today_goods_visit_redio;
          options8.series[0].data[0].value = add_carts.today_add_carts_redio;
          options8.series[0].data[1].value = 100 - add_carts.today_add_carts_redio;
          options9.series[0].data[0].value = payment_people.payment_people_num_today_redio;
          options9.series[0].data[1].value = 100 - payment_people.payment_people_num_today_redio;
          let chartDom = document.getElementById('circle');
          let myChart = echarts.init(chartDom);
          let chartDom2 = document.getElementById('circle2');
          let myChart2 = echarts.init(chartDom2);
          let chartDom3 = document.getElementById('circle3');
          let myChart3 = echarts.init(chartDom3);
          myChart.setOption(options7);
          myChart2.setOption(options8);
          myChart3.setOption(options9);
          this.conversion = res.result;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //获取门店
    getStore() {
      this.$axios.post(this.$api.store.storeList, { rows: 10000 }).then(res => {
        if (res.code == 0) {
          res.result.list.unshift({
            id: 0,
            store_name: '总店',
          });
          if (res.result.list.length) {
            res.result.list.unshift({
              id: -1,
              store_name: '全部',
            });
          }
          this.storeList = res.result.list;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 14px;
  color: #333;
  .select_store {
    background: #fff;
    padding: 15px 20px;
    display: flex;
    .el-form-item {
      margin: 0;
      margin-right: 25px;
    }
  }
  .day-data {
    padding: 0 20px;
    margin-top: 7px;
    background: #fff;
    .header {
      background: #f8f9fb;
      display: flex;
      align-items: center;
      padding-left: 12px;
      height: 40px;
      margin-top: 20px;
      span:nth-child(1) {
        width: 3px;
        height: 11px;
        background: #409eff;
        margin-right: 8px;
      }
      span:nth-child(2) {
        color: #333;
        margin-right: 10px;
      }
      span:nth-child(3) {
        color: #999;
      }
    }
    .day-content {
      margin-top: 40px;
      display: flex;
      padding-left: 25px;
      .day-table {
        flex: 1;
        .day-price {
          p:nth-child(1) {
            color: #333;
            margin-bottom: 12px;
          }
          p:nth-child(2) {
            color: #333;
            font-weight: 500;
            font-size: 18px;
            margin-bottom: 12px;
          }
          p:nth-child(2) {
            color: #999;
            font-size: 14px;
          }
        }
        #day-table {
          height: 260px;
        }
      }
      .day-num {
        flex: 1;
        height: 400px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 40px;
        .item {
          display: flex;
          align-items: center;
          width: 300px;
          margin-bottom: 30px;
          .el-image {
            width: 40px;
            height: 40px;
            margin-right: 20px;
          }
          .price {
            p:nth-child(2) {
              font-size: 22px;
              font-weight: bold;
              margin-top: 10px;
            }
            p:nth-child(3) {
              color: #999;
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
  .data {
    background: #fff;
    margin-top: 7px;
    padding: 10px 20px 20px 20px;
    .header {
      background: #f8f9fb;
      display: flex;
      align-items: center;
      padding-left: 12px;
      height: 40px;
      span:nth-child(1) {
        width: 3px;
        height: 11px;
        background: #409eff;
        margin-right: 8px;
      }
      span:nth-child(2) {
        color: #333;
      }
    }
    .tab {
      position: relative;
      .date {
        position: absolute;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        right: 0;
        top: 0;
        .el-select {
          width: 100px;
          margin-right: 10px;
        }
      }
      .refund_kanban {
        margin-top: 10px;
        .line-table {
          display: flex;
          justify-content: space-between;
          margin-top: 23px;
          .line-table-item {
            flex: 1;
            margin-right: 24px;
            height: 275px;
            background: #f7f8fa;
            padding: 20px;
            display: flex;
            flex-direction: column;
            &:last-child {
              margin: 0;
            }
            .line_num div {
              &:nth-child(2) {
                font-size: 24px;
                font-weight: 500;
                margin-top: 10px;
              }
              &:nth-child(3) {
                color: #999;
                margin-top: 15px;
              }
              &:nth-child(4) {
                color: #999;
                margin-top: 10px;
              }
            }
            #jump-table {
              flex: 1;
            }
          }
        }
        .circle-talbe {
          display: flex;
          .circle-talbe-item {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 300px;
            #circle,
            #circle2,
            #circle3 {
              flex: 1;
              width: 300px;
              height: 200px;
            }
            .circle-talbe-num {
              height: 100px;
              text-align: center;
              div:nth-child(2) {
                margin-top: 12px;
                color: #999;
              }
              div:nth-child(3) {
                margin-top: 7px;
                color: #999;
              }
            }
          }
        }
      }
      .target-pay {
        margin-top: 35px;
        margin-bottom: 30px;
        .target-pay-num {
          display: flex;
          margin-bottom: 40px;
          .item {
            flex: 1;
            p:nth-child(1) {
              font-weight: 500;
              color: #666;
              margin-bottom: 10px;
            }
            p:nth-child(2) {
              font-size: 16px;
              font-weight: 500;
              color: #666;
            }
          }
        }
        #target-pay-table {
          height: 420px;
        }
      }
      .refund-success {
        display: flex;
        margin-top: 20px;
        .refund-success-item {
          flex: 1;
          margin-right: 25px;
          height: 306px;
          background: #f7f8fa;
          padding: 20px;
          display: flex;
          flex-direction: column;
          .refund-num {
            div {
              &:nth-child(2) {
                font-size: 24px;
                font-weight: 500;
                margin-top: 10px;
              }
              &:nth-child(3) {
                color: #999;
                margin-top: 15px;
                display: flex;
                span:nth-child(1) {
                  width: 160px;
                }
              }
              &:nth-child(4) {
                color: #999;
                margin-top: 10px;
                display: flex;
                span:nth-child(1) {
                  width: 160px;
                }
              }
            }
          }
          #refund_success,
          #refund_radio {
            flex: 1;
          }
          &:last-child {
            margin: 0;
          }
        }
      }
      .ranks {
        display: flex;
        margin-top: 20px;
        .goods-rank {
          margin-right: 25px;
        }
        .goods-rank,
        .refund-rank {
          .no {
            margin-top: 20px;
            text-align: center;
            color: #999;
          }
          flex: 1;
          // height: 300px;
          background: #f7f8fa;
          padding: 20px;
          .title {
            color: #666;
            display: flex;
            div:nth-child(1) {
              width: 80px;
            }
            div:nth-child(2) {
              width: 350px;
            }
            div:nth-child(3) {
              flex: 1;
              text-align: center;
            }
            div:nth-child(4) {
              text-align: center;
              flex: 1;
            }
          }
          .item {
            display: flex;
            align-items: center;
            margin-top: 21px;
            .rank {
              color: #999;
              width: 80px;
            }
            .goods-info {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: 350px;
            }
            .num {
              flex: 1;
              color: #999;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
</style>
